
.App {
  max-width: 800px;
  width:90%;
  margin: 2em auto;
  font-size: 1rem;
  font-family:'Comfortaa', cursive;
  font-size: 120%;
  background-color: #404040;
  background-image: url("./media/background2.jpg");
  background-blend-mode:exclusion;
  background-position: center;
  background-size: cover;
  border-radius:1.5em;
}

.app-header h1{
  margin: 0;
  color:white;
  text-shadow: 2px 2px 4px #000000;
}

.app-header, .app-footer{
  text-align: center;
  padding:1em;
  color:white;
  text-shadow: 2px 2px 4px #000000;
 
}

#icon{
  color:#9670f4;
  font-size:1em;
  margin-right: 0.5em;
}

#code{  
  border:1px solid #ddd;
  text-decoration: none;
  padding:0.5em 1em;
  border-radius: 1.5em;
  transition: all 150ms ease-in-out;
  font-weight: bold;
  font-size: 1rem;
  color:#9670f4;
  }

#code:hover{
  background-color: #9670f4;
  color:white;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}