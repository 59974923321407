.photos img {
    border-radius:0.5em;
    margin-bottom: 1em;
    transition: transform 750ms cubic-bezier(.5, 0, .5, 1), opacity 250ms linear;   
}

.photos img:hover{
    transform: scale(1.2);
    opacity:0.5;
}
