
.phonetic{
    margin:1.5em 0;
}

.phonetic a{
    border:1px solid #ddd;
    text-decoration: none;
    padding:0.5em 1em;
    border-radius: 1.5em;
    margin-right:0.5em;
    transition: all 150ms ease-in-out;
    font-weight: bold;
    font-size: 1rem;
    color:#9670f4;
}
.phonetic a:hover{
    background-color: #9670f4;
    color:white;
    cursor: pointer;
    transition: 0.2s ease-in-out;
}

.phonetic .text{
    opacity: 0.7;
    font-weight: bold;
    font-size: 1rem;
}