.meanings h3{
    font-size: 1.5rem;
    line-height:1.5;
    font-weight: bold;
    text-transform: capitalize;
    
}

.examples{
    font-size: 1rem;
    font-style: italic;
    margin-top: 0.25em;
    opacity: 0.7;
}